import React from "react";
import "../estilos/footer.scss";
import logo from "../../assets/imagenes/iconosweet.png";
import { FacebookFilled, InstagramFilled } from "@ant-design/icons";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer__secciones">
          <div className="footer__secciones--columna1">
            <div className="footer__secciones--columna1--imagen">
              <img src={logo} alt="sweet moments" />
            </div>
            <p className="footer__secciones--columna1--descripcion">
              En Sweet Moments, nos esforzamos por ofrecerte la mejor
              experiencia en comida.
            </p>
          </div>
          <div className="footer__secciones--columna2">
            <p className="footer__secciones--columna2--ordena">
              Ordena en linea
            </p>
            <p className="footer__secciones--columna2--descripcion">
              Ordena en linea y recoge tu pedido sin filas.
            </p>
            <p className="footer__secciones--columna2--boton">Ordena</p>
          </div>
          <div className="footer__secciones--columna3">
            <p className="footer__secciones--columna3--contacto">Contáctanos</p>
            <p className="footer__secciones--columna3--descripcion">
              Contáctanos a través de nuestras redes sociales
            </p>
            <p className="footer__secciones--columna3--redes">
              <FacebookFilled />
              <InstagramFilled />
            </p>
          </div>
        </div>
        <div className="footer__desarrollado">
          © Diseñado por Techcode 2024. Todos los derechos reservados
        </div>
      </footer>
    </>
  );
}

export default Footer;
