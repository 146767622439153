import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import { dividirEnGruposCuatro } from "../../informacion/funciones/promociones.js";
import { formateoMoneda } from "../../informacion/utils/funciones.js";
import { Http } from "../../informacion/utils/http.js";
import { endpoints } from "../../informacion/utils/endpoints.js";
import "../estilos/menu.scss";

function Menu() {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    Http.get(endpoints.productos.consultar)
      .then((data) => {
        const promocionesDivididas = dividirEnGruposCuatro(data.payload);
        setProductos(promocionesDivididas);
      })
      .catch((error) => console.error("Error GET:", error));
  }, []);
  return (
    <>
      <section className="menu">
        <h2 className="menu__titulo">Nuestro Menu</h2>
        {productos.length > 0 && (
          <Carousel
            autoplay
            autoplaySpeed={6000}
            dotStyle={{ backgroundColor: "red" }}
            dotActiveStyle={{ backgroundColor: "black" }}
          >
            {productos.map((promocion, index) => (
              <div key={index + "a"} className="menu__carrusel--contenido">
                <div className="contMenu">
                  {promocion.map((prom, index2) => (
                    <div
                      key={index2 + "b"}
                      className="menu__carrusel--contenido__card"
                    >
                      <div className="menu__carrusel--contenido__card--imagen">
                        <img
                          src={"data:image/png;base64," + prom.imagenArchivo}
                        />
                      </div>
                      <div>
                        <div className="menu__carrusel--contenido__card--nombre">
                          {prom.nombre}
                        </div>
                        <div className="menu__carrusel--contenido__card--descripcion">
                          {prom.descripcion}
                        </div>
                        <div className="menu__carrusel--contenido__card--boton">
                          Agregar por {formateoMoneda(prom.precio)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </section>
    </>
  );
}

export default Menu;
