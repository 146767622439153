const endpoints = {
  promociones: {
    guardar: "/promociones/guardar",
    consultar: "/promociones/consultar",
  },
  productos: {
    guardar: "/productos/guardar",
    consultar: "/productos/consultar",
  },
};

export { endpoints };
