import axios from "axios";
import { environments } from "../environments/environments";

const baseURL = environments.urlAPI;

const apiService = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const Http = {
  get: async (url) => {
    try {
      const response = await apiService.get(url);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message || "Error al realizar la petición GET"
      );
    }
  },

  post: async (url, data) => {
    try {
      const response = await apiService.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message || "Error al realizar la petición POST"
      );
    }
  },

  put: async (url, data) => {
    try {
      const response = await apiService.put(url, data);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message || "Error al realizar la petición PUT"
      );
    }
  },

  delete: async (url) => {
    try {
      const response = await apiService.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message || "Error al realizar la petición DELETE"
      );
    }
  },
};

export { Http };
