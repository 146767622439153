import React from "react";
import "../estilos/experiencia.scss";
import chef from "../../assets/imagenes/chef.png";

function Experiencia() {
  return (
    <>
      <section className="experiencia">
        <div className="experiencia__contenido">
          <figure className="experiencia__contenido--imagen">
            <img src={chef} alt="chef cocinando" />
          </figure>
          <article className="experiencia__contenido--texto">
            <p className="experiencia__contenido--texto--pr1">
              ¡Te esperamos con los mejores sabores!
            </p>
            <p className="experiencia__contenido--texto--pr2">
              Desde alitas crujientes hasta postres exquisitos, tenemos todo lo
              que necesitas para una comida increíble. <br />
              <br />
              ¡Ven y disfruta con nosotros!
            </p>
            <div className="experiencia__contenido--texto--boton">Ordenar</div>
          </article>
        </div>
      </section>
    </>
  );
}

export default Experiencia;
