import React from "react";
import "../estilos/navbar.scss";
import logo from "../../assets/imagenes/iconosweetgrande2.png";

function Navbar() {
  return (
    <>
      <nav className="barraNavegacion">
        <div className="contenedor">
          <div className="barraNavegacion__icono">
            <img
              src={logo}
              className="barraNavegacion__icono--imagen"
              alt="icono sweet moments"
            />
          </div>
          <ul className="barraNavegacion__lista">
            <li className="barraNavegacion__lista--enlace">
              <a href="#">Promociones</a>
            </li>
            <li className="barraNavegacion__lista--enlace">
              <a href="#">Salado/Picoso</a>
            </li>
            <li className="barraNavegacion__lista--enlace">
              <a href="#">Dulce</a>
            </li>
            <li className="barraNavegacion__lista--enlace">
              <a href="#">Frappes</a>
            </li>
            <li className="barraNavegacion__lista--boton">
              <a> Ordenar</a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
