import Navbar from "./ui/componentes/navbar";
import Hero from "./ui/componentes/hero";
import "./App.css";
import Promociones from "./ui/componentes/promociones";
import Experiencia from "./ui/componentes/experiencia";
import Menu from "./ui/componentes/menu";
import Footer from "./ui/componentes/footer";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <Promociones />
      <Experiencia />
      <Menu />
      <Footer />
    </>
  );
}

export default App;
