import React from "react";
import "../estilos/hero.scss";
import logo from "../../assets/imagenes/iconosweet.png";
import heroLogo from "../../assets/imagenes/alitas2.jpg";

function Hero() {
  return (
    <>
      <header className="hero">
        <div className="hero__banner">
          <img
            src={heroLogo}
            className="barraNavegacion__icono--imagen"
            alt="icono sweet moments"
          />
        </div>
        <article className="hero__icono">
          <div className="hero__icono--texto">
            <img
              src={logo}
              className="barraNavegacion__icono--imagen"
              alt="icono sweet moments"
            />
          </div>
          <h2 className="hero__icono--descripcion">
            Alitas, Bonneles, Hot Cakes, y Más
          </h2>
        </article>
      </header>
    </>
  );
}

export default Hero;
