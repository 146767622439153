const dividirEnGrupos = (array) => {
  const grupos = [];
  let grupoActual = [];

  array.forEach((elemento, index) => {
    grupoActual.push(elemento);

    if (grupoActual.length === 3 || index === array.length - 1) {
      grupos.push(grupoActual);
      grupoActual = [];
    }
  });

  return grupos;
};

const dividirEnGruposCuatro = (array) => {
  const grupos = [];
  let grupoActual = [];

  array.forEach((elemento, index) => {
    grupoActual.push(elemento);

    if (grupoActual.length === 4 || index === array.length - 1) {
      grupos.push(grupoActual);
      grupoActual = [];
    }
  });

  return grupos;
};

export { dividirEnGruposCuatro, dividirEnGrupos };
