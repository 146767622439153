import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import { dividirEnGrupos } from "../../informacion/funciones/promociones.js";
import { formateoMoneda } from "../../informacion/utils/funciones.js";
import { Http } from "../../informacion/utils/http.js";
import { endpoints } from "../../informacion/utils/endpoints.js";
import "../estilos/promociones.scss";

function Promociones() {
  const [promos, setPromos] = useState([]);

  useEffect(() => {
    Http.get(endpoints.promociones.consultar)
      .then((data) => {
        const promocionesDivididas = dividirEnGrupos(data.payload);
        setPromos(promocionesDivididas);
      })
      .catch((error) => console.error("Error GET:", error));
  }, []);


  return (
    <>
      <section className={`promociones`}>
        <h3 className="promociones__titulo">Promociones del día</h3>
        <article className="promociones__carrusel">
          {promos.length > 0 && (
            <Carousel
              autoplay
              autoplaySpeed={6000}
              dotStyle={{ backgroundColor: "red" }}
              dotActiveStyle={{ backgroundColor: "black" }}
            >
              {promos.map((promocion, index) => (
                <div
                  key={index + "a"}
                  className="promociones__carrusel--contenido"
                >
                  <div className="cont">
                    {promocion.map((prom, index2) => (
                      <div
                        key={index2 + "b"}
                        className="promociones__carrusel--contenido__card"
                      >
                        <div className="promociones__carrusel--contenido__card--imagen">
                          <img
                            src={"data:image/png;base64," + prom.imagenArchivo}
                          />
                        </div>
                        <div className="promociones__carrusel--contenido__card--nombre">
                          {prom.nombre}
                        </div>
                        <div className="promociones__carrusel--contenido__card--descripcion">
                          {prom.descripcion}
                        </div>
                        <div className="promociones__carrusel--contenido__card--precio">
                          <p className="promociones__carrusel--contenido__card--precio--boton">
                            Agregar por {formateoMoneda(prom.precio)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Carousel>
          )}
        </article>
      </section>
    </>
  );
}

export default Promociones;
